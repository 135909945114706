import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, matchPath} from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import ActiveJobs from './pages/ActiveJobs';
import History from './pages/History';
import Login from './pages/Login';
import PrivateRoute from './routes/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import ResetPassword from './pages/ResetPassword';
// import ForgetPassword from './pages/ForgetPassword';
import Signup from './pages/Signup';
const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Layout />
      </Router>
    </AuthProvider>
  );
};

const Layout = () => {
  const location = useLocation();

  // Check if the current route is the login page
  const isLoginPage = location.pathname === '/login' || location.pathname === '/';
  const isResetPasswordPage = matchPath("/reset-password/:token", location.pathname);
  const isforgetPasswordPage = location.pathname === '/forget-password';
  const isSignupPage = location.pathname === '/signup';
  return (
    <div style={appLayout}>
      {!isLoginPage && !isResetPasswordPage && !isforgetPasswordPage && !isSignupPage && <Sidebar />}
      <div style={mainContent}>
        {!isLoginPage && !isResetPasswordPage && !isforgetPasswordPage && !isSignupPage && <Header />} 
        <Routes>
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/forget-password" element={<ForgetPassword  />} />   */}
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/active-jobs"
            element={
              <PrivateRoute>
                <ActiveJobs />
              </PrivateRoute>
            }
          />
          <Route
            path="/history"
            element={
              <PrivateRoute>
                <History />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Login />} />
        </Routes>
      </div>
    </div>
  );
};

const appLayout = {
  display: 'flex',
  height: '100vh',
};

const mainContent = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
};

export default App;
