import React, { useState} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../context/AuthContext';
import Swal from 'sweetalert2';
import { useParams} from "react-router-dom";
const ResetPassword = () => {
  const {forgetPassword } = useAuth();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { token } = useParams(); 

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError("");
    if (newPassword !== confirmPassword) {
       setError("Passwords do not match");
      return;
    }
    Swal.fire({
      title: "Resetting your password...",
      text: "Please wait while we process your request.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    const response = await forgetPassword(newPassword,token);
    
    if (response.success) {
      Swal.fire(
        "Success",
        "The Password has been reset successfully.",
        "success"
      );
      navigate("/login");
    } else {
      Swal.fire("Error", `Error during password reset: ${response.message}`, "error");
      setError(response.message);
    }
  };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center bg-white"
      style={{ flex: 1 }}
    >
      <h3 className="mb-4">Reset your Password</h3>
      <form onSubmit={handlePasswordReset}>
        <div className="mb-3">
          <input
            type="password"
            id="password"
            placeholder="Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <input
            type="password"
            id="confirmPassword"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setError("");
            }}
            className="form-control"
          />
        </div>
        {error && <div className="alert alert-danger text-center">{error}</div>}
        <button
          type="submit"
          className="btn btn-teal w-100 mb-2"
          style={{ backgroundColor: "#006d5b", color: "white" }}
        >
          Reset Password
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
