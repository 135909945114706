import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from "axios";
import CryptoJS from 'crypto-js';
// Create Auth Context
const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Track loading state for app initialization
  useEffect(() => {
    const savedAuthState = localStorage.getItem('isAuthenticated');
    if (savedAuthState === 'true') {
      setIsAuthenticated(true);
    }
    setIsLoading(false); // Loading complete
  }, []);
  /**
   * Login function to authenticate user and persist
   * authentication state in localStorage
   * @param {string} email The user's email address
   * @param {string} password The user's password
   * @returns {boolean} Whether the login was successful
   */
  const login = async(email, password) => {
    // Compare with hardcoded credentials for demonstration purposes
    // TODO: Replace with actual authentication API call

    try{
    const encryptedPassword = CryptoJS.AES.encrypt(password, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    const response=await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/login`,{
      email:email,
      password:encryptedPassword
    })
    if (response.status===200){
      setIsAuthenticated(true);
      localStorage.setItem("isAuthenticated", "true"); // Persist state
      return true;
    }
  }
  catch(err){
    console.error('Error:', err);
    return false;
   // if (email === "admin@shopdev.co" && password === "Shopdev123!@#") {
    //  setIsAuthenticated(true);
     // localStorage.setItem("isAuthenticated", "true"); // Persist state
   //   return true;
    
  };
  }
  /**
   * Logout function to reset authentication state
   * and remove persisted state in localStorage
   */
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Clear persisted state
  };
  
  const signUp= async(email,password)=>{
    try {
      const encryptedPassword = CryptoJS.AES.encrypt(password, process.env.REACT_APP_ENCRYPTION_KEY).toString();
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/signup`,
        {
          email: email,
          password: encryptedPassword,
        }
      );
      if (response.status === 201) {
        return {success:true};
      }
    } catch (error) {
      console.error('Error during signup:', error.response ? error.response.data : error.message);
      return {
        success: false,
        message: error.response?.data?.message || 'Signup failed. Please try again.',
      };
    }
  };
  const forgetPassword = async (password,token) => {
    
    try {
      const encryptedPassword = CryptoJS.AES.encrypt(password, process.env.REACT_APP_ENCRYPTION_KEY).toString();
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/reset-password/${token}`,
        {
          newPassword: encryptedPassword,
        }
      );
      if (response.status === 200) {
        return { success: true }; // Indicate success
      } else if (response.status === 404) {
        return { success: false, message: "Email not found. Please check and try again." };
      }
    } catch (error) {
      console.error(
        "Error during password reset:",
        error.response ? error.response.data : error.message
      );
  
      // Check if the error has a specific response
      if (error.response && error.response.data && error.response.data.message) {
        return { success: false, message: error.response.data.message }; // Return API error message
      }

      return { success: false, message: "An unexpected error occurred. Please try again." };
    }
  };
  const resetPassword = async (email) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/forgot-password`,
        {
          email: email,
        }
      );
  
      if (response.status === 200) {
        return { success: true }; // Indicate success
      } else if (response.status === 404) {
        return { success: false, message: "Email not found. Please check and try again." };
      }
    } catch (error) {
      console.error(
        "Error during password reset:",
        error.response ? error.response.data : error.message
      );
  
      // Check if the error has a specific response
      if (error.response && error.response.data && error.response.data.message) {
        return { success: false, message: error.response.data.message }; // Return API error message
      }
  
      return { success: false, message: "An unexpected error occurred. Please try again." };
    }
  };
  
  /**
   * Returns the current authentication state and
   * functions for login and logout
   */

  return (
    <AuthContext.Provider value={{ isAuthenticated, signUp, resetPassword, forgetPassword, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
