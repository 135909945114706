import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Logobar from "./Logobar";
const Login = () => {
  const { isAuthenticated, login } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  if (isAuthenticated) {
    navigate("/dashboard"); // Redirect to dashboard after login
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    const success = await login(email, password);
    if (success) {
      Swal.fire("Success", "User logged in successfully", "success");
      navigate("/dashboard");
    } else {
      setError("Invalid email or password");
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    return emailRegex.test(email);
  };
  
  return (
    <div className="d-flex vh-100 d-flex flex-wrap">
      <Logobar />
      <div
        className="d-flex justify-content-center align-items-lg-center bg-light"
        style={{ flex: 1 }}
      >
        <div
          className="p-4 shadow-sm"
          style={{ maxWidth: "400px", width: "100%" }}
        >
          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
              />
            </div>
            {error && (
              <div className="alert alert-danger text-center">{error}</div>
            )}
            <button
              type="submit"
              className="btn btn-teal w-100 mb-2"
              style={{ backgroundColor: "#006d5b", color: "white" }}
              disabled={!validateEmail(email) || password.trim() === ""}
            >
              Login
            </button>
          </form>

          <div className="text-center d-flex justify-content-between">
            <>
              <button
                onClick={() => {
                  navigate("/signup");
                }}
                className="btn btn-link text-muted p-0 mb-2"
                style={{ marginTop: "30px" }}
              >
                Sign up
              </button>
              {/* <button
                onClick={() => {
                  navigate("/forget-password");
                }}
                className="btn btn-link text-muted p-0 mb-2"
                style={{ marginTop: "30px" }}
              >
                Forgotten Password?
              </button> */}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
