import React, { useState } from "react";
import { Table, Button, Spin, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import UploadColumn from "./UploadColumn";

const JobTable = ({
  jobs,
  niaLoading,
  pcdLoading,
  ssLoading,
  setSelectedJobId,
  setShowModal,
  callSummeryReportApi,
  callParentChildDistroApi,
  callStopSellReportApi,
  loading,
  deleteJob,
  setFileType,
}) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (text) => {
    setSearchText(text.toLowerCase().trim());
  };

  const filteredJobs = (jobs ?? [])?.filter?.((job) => {
    const searchWords = searchText.split(" ").filter(Boolean);
    const jobIdString = job.jobId.toString().toLowerCase();
    const statusString = job.status.toLowerCase();

    return searchWords.every(
      (word) => jobIdString === word || statusString.includes(word)
    );
  });

  const handleUpload = (columnType, jobId) => {
    setSelectedJobId(jobId);
    setShowModal(true);
    setFileType(columnType);
  };

  const columns = [
    {
      title: "Job ID",
      dataIndex: "jobId",
      key: "jobId",
      align: "center",
      width: "8%",
      sorter: (a, b) => a.jobId - b.jobId,
      defaultSortOrder: "descend",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: "12%",
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },

    ...[
      { title: "PIM", key: "isProduct", columnType: "PimProduct" },
      { title: "BOM", key: "isBom", columnType: "BomProduct" },
      { title: "IMR", key: "isIMR", columnType: "IMR" },
      { title: "DSR", key: "isDSR", columnType: "DSR" },
      { title: "Demantra", key: "isDementra", columnType: "Demantra" },
      { title: "USC", key: "isUCR", columnType: "UnsubmittedCart" },
      { title: "RMA", key: "isRMA", columnType: "RMABuffer" },
      { title: "KP", key: "isKP", columnType: "ProtectedKit" },
    ].map(({ title, key, columnType }) => ({
      title,
      dataIndex: key,
      key,
      align: "center",
      width: "7%",
      render: (value, record) => {
        return (
          <UploadColumn
            value={value}
            record={record}
            columnType={columnType}
            handleUpload={handleUpload}
          />
        );
      },
    })),
    {
      title: "NIA",
      key: "nia",
      align: "center",
      width: "5%",
      render: (_, record) =>
        record.isProduct &&
        record.isBom &&
        record.isIMR &&
        record.isDSR &&
        record.isDementra &&
        record.isUCR &&
        record.isRMA &&
        record.isKP ? (
          <Button
            onClick={() => callSummeryReportApi(record.jobId)}
            disabled={niaLoading[record.jobId]}
            type="primary"
            shape="circle"
            icon={
              niaLoading[record.jobId] ? (
                <Spin size="small" />
              ) : (
                <FontAwesomeIcon icon={faDownload} />
              )
            }
          />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        ),
    },
    {
      title: "PCD",
      key: "pcd",
      align: "center",
      width: "5%",
      render: (_, record) =>
        record.isSummery ? (
          <Button
            onClick={() => callParentChildDistroApi(record.jobId)}
            disabled={pcdLoading[record.jobId]}
            type="primary"
            shape="circle"
            icon={
              pcdLoading[record.jobId] ? (
                <Spin size="small" />
              ) : (
                <FontAwesomeIcon icon={faDownload} />
              )
            }
          />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        ),
    },
    {
      title: "SS",
      key: "ss",
      align: "center",
      width: "5%",
      render: (_, record) =>
        record.isSummery && record.isPCD ? (
          <Button
            onClick={() => callStopSellReportApi(record.jobId)}
            disabled={ssLoading[record.jobId]}
            type="primary"
            shape="circle"
            icon={
              ssLoading[record.jobId] ? (
                <Spin size="small" />
              ) : (
                <FontAwesomeIcon icon={faDownload} />
              )
            }
          />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        ),
    },
    {
      title: "U-NIA",
      key: "isSummery",
      dataIndex: "isSummery",
      align: "center",
      width: "10%",
      render: (value, record) => {
        const allConditionsMet =
          record.isProduct &&
          record.isBom &&
          record.isIMR &&
          record.isDSR &&
          record.isDementra &&
          record.isUCR &&
          record.isRMA &&
          record.isKP &&
          record.isSummery;
    
        const handleConditionalUpload = (value, record) => {
          if (!allConditionsMet) {
            return; // Prevent uploading if conditions are not met
          }
          handleUpload(value, record); // Call the original handleUpload if conditions are met
        };
    
        return (
          <UploadColumn
            value={false}
            record={record}
            columnType="UpdateSummaryReport"
            handleUpload={handleConditionalUpload} 
            disabled={!allConditionsMet} 
            style={{
              opacity: allConditionsMet ? 1 : 0.5, 
              pointerEvents: allConditionsMet ? "auto" : "none", 
            }}
          />
        );
      },
    },    
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "10%",
      render: (status) => (status === "inprogress" ? "In Progress" : status),
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      width: "25%",
      render: (_, record) => (
        <Button
          type="success"
          style={{ backgroundColor: "#E9C99C" }}
          key="deleteJob"
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "This action cannot be undone!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d33",
              cancelButtonColor: "#3085d6",
              confirmButtonText: "Yes",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.isConfirmed) {
                deleteJob(record.jobId); // Call deleteJob with jobId
                Swal.fire("Deleted!", "The job has been deleted.", "success");
              }
            });
          }}
        >
          Delete Job
        </Button>
      ),
    },
  ];

  const dataSource = filteredJobs.map((job) => ({
    ...job,
    key: job.jobId,
  }));

  return (
    <div className="w-full" style={{ fontFamily: "Poppins" }}>
      <Input
        placeholder="Search by Job ID or Status"
        onChange={(e) => handleSearch(e.target.value)}
        style={{ marginBottom: "20px", width: "300px" }}
      />
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        locale={{
          emptyText: loading ? <Spin tip="Loading jobs..." /> : "No jobs found",
        }}
        pagination={{ pageSize: 10 }}
        style={{
          margin: "20px 0",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          overflow: "auto",
        }}
        size="small"
      />
    </div>
  );
};

export default JobTable;
