import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import pamperedChefLogo from '../assets/logo2.png';  // Import the image

const Logobar = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center bg-white" style={{ flex: 1 }}>
    <div className="text-center">
      <div style={{
        fontFamily: "serif", fontWeight: "bold", display: "flex", alignItems: "center", fontSize: "40px",
      }}>
        {/* Logo */}
        <img
          src={pamperedChefLogo}
          alt="Pampered Chef Logo"
          style={{
            width: "350px", height: "100px", marginRight: "10px", objectFit: "contain",
          }}
        />
      </div>
      <p className="text-center text-muted" style={{ fontStyle: "italic", color: "#D6B2A1", maxWidth: "400px", fontWeight: "normal", fontSize: "16px" }}>
        Enriching lives, one meal and one memory at a time.
      </p>
    </div>
    <p className="text-muted">Inventory Optimization</p>
  </div>
  );
};

export default Logobar;

