import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import Swal from "sweetalert2";
import Logobar from "./Logobar";
import { useNavigate } from "react-router-dom";
const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const { signUp } = useAuth();
  const handleSignup = async (e) => {
    e.preventDefault();
    setError("");

    if (password === confirmPassword) {
      const result = await signUp(email, password);
      if (result.success) {
        Swal.fire("Success", "User registered successfully", "success");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        navigate("/login")
      } else {
        setError(result.message);
      }
    } else {
      setError("Password does not match");
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    return emailRegex.test(email);
  };
  
  return (
    <div className="d-flex vh-100 d-flex flex-wrap">
      <Logobar />
      <div
        className="d-flex justify-content-center align-items-lg-center bg-light"
        style={{ flex: 1 }}
      >
        <div
          className="p-4 shadow-sm"
          style={{ maxWidth: "400px", width: "100%" }}
        >
          <form onSubmit={handleSignup} onClick={() => setError("")}>
            <div className="mb-3 ">
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                id="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setError("");
                }}
                className="form-control"
              />
            </div>
            {error && (
              <div className="alert alert-danger text-center">{error}</div>
            )}
            <button
              type="submit"
              className="btn btn-teal w-100 mb-2"
              style={{ backgroundColor: "#006d5b", color: "white" }}
              disabled={
                !validateEmail(email) ||
                password.trim() === "" 
              }
            >
              Sign up
            </button>
          </form>

          <div className="text-center d-flex justify-content-between">
            <button
              onClick={() => {
                navigate("/login");
              }}
              className="btn btn-link text-muted p-0 mb-2"
              style={{ marginTop: "30px" }}
            >
              Back to Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
