import React from "react";
import { UploadOutlined, CheckOutlined } from "@ant-design/icons";

const UploadColumn = ({ value, record, columnType, handleUpload, disabled }) => {
  return (
    <div style={{ display: "flex", alignItems: "center",justifyContent: "center", gap: "8px" }}>
      {value ? (
        <CheckOutlined
          style={{ color: "green" }}
          onClick={() => !disabled && handleUpload(columnType, record.jobId)} // Prevent upload if disabled
        />
      ) : (
        <>
          {/* <span style={{ color: disabled ? "#ccc" : "#000" }}>No</span> */}
          <UploadOutlined
            style={{
              marginLeft: 8,
              cursor: disabled ? "not-allowed" : "pointer",
              color: disabled ? "#ccc" : "#1890ff",
            }}
            onClick={() => !disabled && handleUpload(columnType, record.jobId)} // Prevent upload if disabled
          />
        </>
      )}
    </div>
  );
};

export default UploadColumn;
